<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="店铺" style="width: 200px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="listQuery.activity_type" class="filter-item" placeholder="活动类型" style="width: 140px" clearable>
        <el-option label="门店新客" :value="1"></el-option>
        <el-option label="满减活动" :value="2"></el-option>
        <el-option label="会员特价" :value="3"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
<!--      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>-->
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school?scope.row.school.school_name:'' }}
        </template>
      </el-table-column>
      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop?scope.row.shop.shop_name:'' }}
        </template>
      </el-table-column>
      <el-table-column label="活动类型" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.activity_type === 1">门店新客</span>
          <span v-else-if="scope.row.activity_type === 2">满减活动</span>
          <span v-else-if="scope.row.activity_type === 3">会员特价</span>
        </template>
      </el-table-column>
      <el-table-column label="优惠条件" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.discount" :key="index">满{{item.minimum}}减{{item.amount}}</div>
        </template>
      </el-table-column>
      <el-table-column label="开始时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.start_time }}
        </template>
      </el-table-column>
      <el-table-column label="结束时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.end_time }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.state == 0">关闭</el-tag>
          <el-tag type="success" v-if="scope.row.state == 1">启动</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="280" class-name="small-padding fixed-width">
        <template slot-scope="scope">
<!--          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>-->
          <el-button type="danger" size="mini" @click="handleDel(scope.row)" v-if="scope.row.activity_type != 3">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

<!--    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">-->
<!--      <el-form ref="form" :model="form" label-position="left" label-width="90px">-->
<!--        <el-form-item label="店铺">-->
<!--          <el-select class="filter-item" v-model="form.shop_id" placeholder="请选择店铺" style="width: 140px">-->
<!--            <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="活动类型" prop="activity_type">-->
<!--          <el-select class="filter-item" v-model="form.activity_type" placeholder="请选择活动类型" style="width: 140px">-->
<!--            <el-option label="门店新客" :value="1"></el-option>-->
<!--            <el-option label="满减活动" :value="2"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="开始时间">-->
<!--          <el-date-picker v-model="form.start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="请选择开始时间" :picker-options="pickerOptions"></el-date-picker>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="结束时间">-->
<!--          <el-date-picker v-model="form.end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="请选择结束时间" :picker-options="pickerOptions"></el-date-picker>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="dialogVisible = false">取消</el-button>-->
<!--        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date().toLocaleDateString()).getTime();
        },
      },
      shops: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_id: "",
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        shop_id: "",
        activity_type: 1,
        discount: "",
        start_time: "",
        end_time: "",
      },
    };
  },
  created() {
    this.getList();
    this.getShopList();
  },
  mounted() {},
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          shop_id: "",
          activity_type: 1,
          discount: "",
          start_time: "",
          end_time: "",
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/shopActivity/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(item) {
      this.form = Object.assign({}, item)
      this.dialogVisible = true;
    },
    handleDel(item) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/shopActivity/del",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    saveData() {
      if (!this.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      } else {
        this.form.school_id = this.school_id;
      }
      if (!this.form.shop_id) {
        this.$message({
          type: "warning",
          message: "请选择店铺"
        });
        return;
      }
      if (this.form.discount <= 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的优惠条件"
        });
        return;
      }
      if (!this.form.start_time) {
        this.$message({
          type: "warning",
          message: "请选择开始时间"
        });
        return;
      }
      if (!this.form.end_time) {
        this.$message({
          type: "warning",
          message: "请选择结束时间"
        });
        return;
      }
      if (this.form.end_time < this.form.start_time) {
        this.$message({
          type: "warning",
          message: "结束时间不能低于开始时间"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/shopActivity/save",
        method: "post",
        data: this.form
      }).then(() => {
        this.btnLoading = false;
        this.dialogVisible = false;
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
</style>
